const SPACING_CONFIG = {
  top: {
    "m-Accordion": "m",
    "m-CardGrid": "m",
    "m-CardRow": "l",
    "m-Divider": "m",
    "m-DownloadList": "l",
    "m-DownloadTeaser": "l",
    "m-Excerpt": "l",
    "m-Headline": "m",
    "m-Image": "m",
    "m-ImageCarousel": "m",
    "m-ImageCarousel m-ImageCarousel--analyse": "m",
    "m-Media": "m",
    "m-MediaTextSlider": "m",
    "m-MultiColumnTeasers": "m",
    "m-Opener": "m",
    "m-Quote": "l",
    "m-RichText": "m",
    "m-SpeakerGrid": "m",
    "m-TextHighlight": "l",
    "m-TextHighlight m-TextHighlight--alternative": "l",
    "m-TextHighlight m-TextHighlight--image": "l",
    "m-TextImage m-TextImage--quote": "l",
    "m-TextImage": "m",
    "m-TextMedia": "m",
  },
  bottom: {
    "m-CardRow": "l",
    "m-Excerpt": "l",
    "m-TextHighlight": "l",
    "m-TextHighlight m-TextHighlight--alternative": "l",
    "m-TextHighlight m-TextHighlight--image": "l",
    "m-TextImage m-TextImage--quote": "l",
  },
}
// possible return values: "xs", "s", "m", "l", "xl"

function defaultSpacingTop(moduleName) {
  let config = SPACING_CONFIG

  let spacing =
    config && config.top && config.top[moduleName]
      ? config.top[moduleName]
      : "none"

  return spacing
}

function defaultSpacingBottom(moduleName) {
  let config = SPACING_CONFIG

  let spacing =
    config && config.bottom && config.bottom[moduleName]
      ? config.bottom[moduleName]
      : "none"
  return spacing
}

module.exports = {
  defaultSpacingTop,
  defaultSpacingBottom,
}
